import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, CircularProgress } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import textImage from './assets/text.png';
import adsImage from './assets/ads.png';
import bookImage from './assets/book.png';
import startImage from './assets/star.png';
import flyImage from './assets/fly.png';
import logo from './assets/logo.png';

const App = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    nationality: '',
    city: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const { name, email, nationality, city } = formData;
      if (name && email && nationality && city) {
        setOpen(true);
      } else {
        alert('Please fill out all fields.');
      }
    }
  };

  const handleClose = async (confirm) => {
    if (confirm) {
      setOpen(false);
      setLoading(true);
      try {
       
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('nationality', formData.nationality);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('image', selectedImage);

       
        const response = await fetch('https://sadia.symloop.com/api/information', {
          method: 'POST',
          body: formDataToSend,
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          toast.success('Information uploaded successfully!');

          setFormData({
            name: '',
            email: '',
            nationality: '',
            city: '',
          });
          setSelectedImage(null);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message || 'Something went wrong'}`);
        }
      } catch (error) {
        console.error('Error uploading the information', error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false); // Hide the loading indicator
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', margin: 0, padding: 0 }}>
      <Box 
        sx={{ 
          position: 'relative', 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          margin: 0,
          padding: 0
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0, // Adjust this value to position the circle as needed
            width: 120,
            height: 120,
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
         <img
              src={logo} 
              alt="circle"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        </Box>
      </Box>
      <AppBar 
        position="static" 
        sx={{ 
          bgcolor: 'white', 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' 
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
         
        </Toolbar>
      </AppBar>
      <Box 
        sx={{ 
          flexGrow: 1, 
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          padding: 0,
          '@media (min-width: 600px)': {
            flexDirection: 'row',
          }
        }}
      >
        <Box 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            padding: 2,
            position: 'relative'
          }}
        >
          <img src={textImage} alt="Text" style={{ position: 'absolute', top: '10%', left: '10%', height: 170 }} />
          <img src={adsImage} alt="Ads" style={{ position: 'absolute', top: '40%', left: '20%', height: 280 }} />
          <img src={bookImage} alt="Book" style={{ position: 'absolute', bottom: 0, right: 0 }} />
          <img src={startImage} alt="Start" style={{ position: 'absolute', top: '35%', right: 0 }} />
          <img src={flyImage} alt="Fly" style={{ position: 'absolute', top: '25%', left: 0 }} />
        </Box>

        <Box 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            padding: 2,
            '@media (min-width: 600px)': {
              padding: 1,
            },
            '@media (min-width: 900px)': {
              padding: 3,
            }
          }}
        >
          {/* Hidden file input */}
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
           
          </label>
          {/* Right container with text fields */}
          <TextField 
            variant="outlined" 
            placeholder="NAME" 
            name="name"
            value={formData.name}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'transparent',
              borderRadius: 5,
              marginBottom: 2, 
              width: '80%', 
              border: '2px dashed white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="EMAIL" 
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'transparent',
              borderRadius: 5,
              marginBottom: 2, 
              width: '80%', 
              border: '2px dashed white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="NATIONALITY" 
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'transparent',
              borderRadius: 5,
              marginBottom: 2, 
              width: '80%', 
              border: '2px dashed white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="CITY" 
            name="city"
            value={formData.city}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'transparent',
              borderRadius: 5,
              width: '80%', 
              border: '2px dashed white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <Box 
            sx={{ 
              backgroundColor: 'white', 
              marginTop: 2,
              color: 'black', 
              fontWeight: 'bold', 
              textAlign: 'center', 
              borderRadius: 5, 
              width: '75%', 
              marginBottom: 2,
              padding: 2,
              fontSize: '1.8rem', 
              cursor: 'pointer' 
            }}
            onClick={() => document.getElementById('raised-button-file').click()}
          >
            Upload Receipt
          </Box>
          <img src={startImage} alt="Start" style={{ position: 'absolute', top: '60%', right: 0 }} />
        </Box>
      </Box>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Upload Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to upload the receipt?
          </DialogContentText>
          {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} sx={{ fontFamily: 'MyFont', fontWeight: 'bold' }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default App;
