import React from 'react';
import { useMediaQuery, CssBaseline } from '@mui/material';
import DesktopComponent from './DesktopComponent';
import TabletComponent from './TabletComponent';
import MobileComponent from './MobileComponent';

const App = () => {
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1199px)');
  const isMobile = useMediaQuery('(max-width:599px)');

  return (
    <div>
      <CssBaseline />
      {isDesktop && <DesktopComponent />}
      {isTablet && <TabletComponent />}
      {isMobile && <MobileComponent />}
    </div>
  );
};

export default App;
